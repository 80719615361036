import React from "react";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import lslogopng from '../../assets/lslogopng.png';
import {FaUserAlt} from 'react-icons/fa';
import {BsFillBriefcaseFill} from 'react-icons/bs';
import {FaGraduationCap} from 'react-icons/fa';
import {TbCertificate} from 'react-icons/tb';
import {MdWeb} from 'react-icons/md';
import {AiFillInfoCircle} from 'react-icons/ai';

const scrollEducation = (event) => {
    event.preventDefault(); 
    const Education = document.getElementById('Education');
    if (Education) {
      Education.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
    } 
  };

  const scrollTop = (event) => {
    event.preventDefault(); 
    const Top = document.getElementById('Top');
    if (Top) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } 
  }

  const scrollAbout = (event) => {
    event.preventDefault(); 
    const About = document.getElementById('About');
    if (About) {
        About.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollWork = (event) => {
    event.preventDefault(); 
    const Work = document.getElementById('Work');
    if (Work) {
        Work.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollCertifications = (event) => {
    event.preventDefault(); 
    const Certifications = document.getElementById('Certifications');
    if (Certifications) {
        Certifications.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }


  const scrollProjects = (event) => {
    event.preventDefault(); 
    const Projects = document.getElementById('Projects');
    if (Projects) {
        Projects.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollContacts = (event) => {
    event.preventDefault(); 
    const Contacts = document.getElementById('Contacts');
    if (Contacts) {
        Contacts.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  


const Toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar_navigation">
            <div className="toolbar__toggle-button">
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
                <div className="toolbar__logo" id="Top">
                    <a href="#Top" onClick={scrollTop}>
                    <img src={lslogopng}  alt="Home" height={50} width={50} />
                    </a>
                </div>
                <div className="spacer"/>
            <div className="toolbar_navigation-item">
                <ul>
                    <li>
                    <a href="#About" className="icon" onClick={scrollAbout}>
                        <span>
                        <FaUserAlt 
                        style={{height:'16px', width:'16px', marginRight:'5px'}}/>
                       About

                        </span>
                        </a>
                        </li>

                    <li>
                        
                    <a href="#Work" onClick={scrollWork} className="icon">
                        <span>
                        <BsFillBriefcaseFill 
                        style={{height:'16px', width:'16px', marginRight:'5px'}}/>
                       Work

                        </span>
                        </a>
                        </li>
                        <li>
                        <a href="#Education" onClick={scrollEducation} className="icon">
                        <span>
                        <FaGraduationCap 
                        style={{height:'18px', width:'18px', marginRight:'5px'}}/>
                       Education

                        </span>
                        </a>
                        </li>
                        <li>
                        <a href='Certifications' onClick={scrollCertifications} className="icon">
                        <span>
                        <TbCertificate 
                        style={{height:'18px', width:'18px', marginRight:'5px'}}/>
                       Certifications

                        </span>
                        </a>
                        </li>         
                        <li>
                        <a href="Projects" className="icon"  onClick={scrollProjects}>
                        <span>
                        <MdWeb
                        style={{height:'18px', width:'18px', marginRight:'5px'}}/>
                       Projects

                        </span>
                        </a>
                        </li>                       
                        <li>
                        <a href="Contacts" className="icon"  onClick={scrollContacts}>
                        <span>
                        <AiFillInfoCircle 
                        style={{height:'18px', width:'18px', marginRight:'5px'}}/>
                       Contacts

                        </span>
                        </a>
                        </li> 
                </ul>
            </div>
        </nav>
    </header>
);

export default Toolbar;