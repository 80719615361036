import React from "react";
const TimelineItem = ({ data }) => (
    <div className="timeline-item">
            <div className="timeline-item-content">
                <span className="tag" style={{background: data.category.color, borderRadius:'5px', padding:'3px'}}>
                    <text style={{color:'#c2c2c2', fontWeight:'bold'}}>{data.date}</text>
                </span>
                <time style={{marginBottom:'-5px'}}>{data.time}</time>
                <p style={{color:'#c2c2c2', marginBottom:'-8px', fontFamily:'Newake', fontSize:'20px'}}>{data.type}</p>
                <p style={{color:'#c2c2c2',  marginBottom:'-10px', fontSize:'13px', fontFamily:'Verdana'}}>{data.text}</p>
                <p style={{color:'#c2c2c2', fontSize:'15px', opacity:'70%'}}>{data.description}</p>
                <span className="circle"></span>
            </div>

    </div>
);
export default TimelineItem;