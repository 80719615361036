import React from "react";
import {FaGraduationCap} from 'react-icons/fa';
import Timeline from "./Timeline";

const Education = () => (
    <div>
    <div className="title">
    <div style={{marginBottom:'50px'}}/>
<div className="couplework">
                        <span>
                        <FaGraduationCap 
                        style={{height:'22px', width:'22px', marginRight:'15px', color:'#c2c2c2'}}/>
                       <text style={{color:'#c2c2c2', fontSize:'22px'}}>Education</text>
                        </span>
                        </div>                   
</div>
    <Timeline/>
    <div style={{marginBottom:'100px'}}>

                                                </div>
    </div>
);

export default Education;

