import React from "react";
import {MdWeb} from 'react-icons/md';
import { Link } from "react-router-dom";




const Projects = () => (
    <div>
    <div style={{  display: 'flex',
   }}>
<div className="couplework">
                        <span>
                        <MdWeb 
                        style={{height:'22px', width:'22px', marginRight:'15px', color:'#c2c2c2'}}/>
                       <text style={{color:'#c2c2c2', fontSize:'22px'}}>Projects</text>
                        </span>
                        </div>

                         
</div>
<div style={{marginBottom:'50px'}}>

                                                </div>
    <div className="cont">

    <div className="trapezioPos"> 
    </div>
  
    <div className="triangolo">
    <text className="PosTitle">POS Digital</text>
    <text className="PosDescription">A website for business task management.</text>
  <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/htmllogo.png?alt=media&token=f1e5c53d-d8b5-4d0b-bf2f-383740420d6c"} className='firstimg' alt="Home"   />
                       <text className="firsttext">HTML</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/csslogo.png?alt=media&token=d992ea56-5234-496f-9ed4-b13b00fc237c"} className='secondimg' alt="Home"  />
                       <text className="secondtext">CSS</text>
                        </div>

                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/bootstraplogo.png?alt=media&token=61d608c1-6e29-4f82-bae0-40a11de00751"} className='thirdimg' alt="Home"   />
                       <text className="thirdtext">Bootstrap</text>
                        </div>

                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/oraclelogo.png?alt=media&token=77975422-389c-4025-b465-52d363edad87"} className='fourthimg' alt="Home"  />
                       <text className="fourthtext">Oracle</text>
                        </div>
    </div>
  </div>
 
  <div style={{marginBottom:'50px'}}>

                                                </div>
  <div className="cont">

    <div className="trapezioGen"></div>
  
    <div className="triangolo">
    <text className="GenTitle">Gen</text>
    <text className="GenDescription">Share notes and get organized with your friends.
  </text>
  <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/reactlogo.png?alt=media&token=746f5a0b-52b2-4f9f-aaf9-50d7b2915ffe"} className='firstimg' alt="Home"   />
                       <text className="firsttext">React</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/javascriptlogo.png?alt=media&token=2424a6d8-3d3a-4d71-898b-4c9b6b004bcb"} className='secondimggen' alt="Home"  />
                       <text className="secondtext">JavaScript</text>
                        </div>

                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/firebaselogo.png?alt=media&token=845116f4-b97c-45a4-9a79-7b2f12b40a6f"} className='thirdimggen' alt="Home"   />
                       <text className="thirdtext">Firebase</text>
                        </div>

                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/expologo.png?alt=media&token=1ee4537d-ecda-4cdf-a374-3ef4c81db3e9"} className='fourthimg' alt="Home"  />
                       <text className="fourthtext">Expo</text>
                        </div>
    </div>
  </div>


  <div style={{marginBottom:'100px'}}>

                                                </div>
  </div>
);

export default Projects;