import React from "react";
import {AiFillInfoCircle} from 'react-icons/ai';
import {MdEmail} from 'react-icons/md';
import {AiFillFacebook, AiFillLinkedin, AiFillInstagram, } from 'react-icons/ai';
import {BsTelegram} from 'react-icons/bs';




const About = props => (
    <div>
<div style={{  display: 'flex',
   }}>
<div className="couplework" style={{marginBottom:'20px'}}>
                        <span>
                        <AiFillInfoCircle 
                        style={{height:'22px', width:'22px', marginRight:'15px', color:'#c2c2c2'}}/>
                       <text style={{color:'#c2c2c2', fontSize:'22px'}}>Contacts</text>
                        </span>
                        </div>

                         
</div>


                        <div className="wewe" style={{marginTop:'50px'}}>
                            <a href="mailto:leonardsutera@gmail.com" className="www">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/5email.png?alt=media&token=b3409eea-e25e-4d14-b186-afe04e1c97c9"} className="mailStyle"  />
                       </a>

                      <a href="https://it.linkedin.com/in/leonard-sutera-916b78242" target={"_blank"}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/1linkedin.png?alt=media&token=fad96726-5185-4664-855b-7e1d0b6411c1"} className="mailStyle"   />
                       </a>

                        <a href="https://t.me/leonard27" target={"_blank"}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/2telegram.png?alt=media&token=a2afe7fe-b564-4753-a5d1-43a9f9889951"} className="mailStyle"   />
                        </a>

                        <a href="https://www.instagram.com/leonardsutera/" target={"_blank"}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/3instagram.png?alt=media&token=93752980-232f-4bf2-8d81-93ee20429fce"} className="mailStyle"   />
                       </a>
                       
                       <a href="https://www.facebook.com/leonard.sutera/" target={"_blank"}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/4facebook.png?alt=media&token=722269c8-e536-4a3d-8cf2-211c6c3f7166"} className="mailStyle"   />
                        </a>
                       

                        </div>
                        <div className="princlang">
                        <text style={{marginBottom:'30px'}}></text>
                        </div>
                                                 

                                                <div style={{marginBottom:'100px'}}>

                                                </div>
</div>

);

export default About;