import React from "react";
import {FaUserAlt} from 'react-icons/fa';







const About = props => (
    <div>
<div style={{  display: 'flex',
   }}>
<div className="couplework">
                        <span>
                        <FaUserAlt 
                        style={{height:'22px', width:'22px', marginRight:'15px', color:'#c2c2c2'}}/>
                       <text style={{color:'#c2c2c2', fontSize:'22px'}}>About</text>
                        </span>
                        </div>

                         
</div>
<div className="spanAbout">
                       <text className="text" > I'm a 22-year-old Italian with a deep passion for computer science. I love learning about the latest advancements in technology and applying that knowledge to experiment with.
        </text> 
        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/idapple.jpeg?alt=media&token=9c3770c4-f37f-4ee3-a008-b7f805e5eb0e"} className="appleimage" alt="Home"   />

                        </div>
                        <div className="princlang">
                        <text style={{marginBottom:'30px'}}></text>
                        </div>
                        <div className="span3">

                        <div>
                        <div className="spantitle">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/codinglogo.png?alt=media&token=81bed594-f926-4424-8bc8-dc7ca7de8cce"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana', fontWeight:'bold'}}>Programming Languages</text>
                        </div>
                        <div className="spanelement" style={{marginBottom:'5px'}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/javascriptlogo.png?alt=media&token=2424a6d8-3d3a-4d71-898b-4c9b6b004bcb"} className="imageapple" alt="Home" height={25} width={25}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>JavaScript</text>
                        </div>
                        <div className="spanelement" style={{marginBottom:'3px'}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/javalogo.png?alt=media&token=d43215dd-2a61-4c60-bfe3-dae0277a6a33"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Java</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/htmllogo.png?alt=media&token=f1e5c53d-d8b5-4d0b-bf2f-383740420d6c"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>HTML</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/csslogo.png?alt=media&token=d992ea56-5234-496f-9ed4-b13b00fc237c"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>CSS</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/pythonlogo.png?alt=media&token=c8df3866-d5d5-4e2d-a6a5-aba6f7a6ab93"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Python</text>
                        </div>
                        </div>  
                        
                        <div>
                        <div className="spantitle">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/framew.png?alt=media&token=b8a4f072-288c-4397-b68f-31f43caf97be"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana',  fontWeight:'bold'}}>Libraries & Frameworks</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/vuelogo.png?alt=media&token=2fe64217-5bfa-43cc-b04a-d3423eac7320"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Vue JS</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/reactlogo.png?alt=media&token=746f5a0b-52b2-4f9f-aaf9-50d7b2915ffe"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>React</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/nodelogo.png?alt=media&token=0ddd1b0b-3baa-4eef-9389-26cffdde76b2"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Node.js</text>
                        </div>
                        <div className="spanelement" style={{marginTop:'5px'}}>
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/bootstraplogo.png?alt=media&token=61d608c1-6e29-4f82-bae0-40a11de00751"} className="imageapple" alt="Home" height={25} width={25}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Boostrap</text>
                        </div>
                        </div>


                        <div>
                        <div className="spantitle">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/tools.png?alt=media&token=d1a6ffb2-1adf-42b5-a789-85cf0bb6c38e"} className="imageapple" alt="Home" height={25} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana',  fontWeight:'bold'}}>Tools & Platforms</text>
                        </div> 
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/firebaselogo.png?alt=media&token=845116f4-b97c-45a4-9a79-7b2f12b40a6f"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Firebase</text>
                        </div> 
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/oraclelogo.png?alt=media&token=77975422-389c-4025-b465-52d363edad87"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Oracle</text>
                        </div>
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/mysqllogo.png?alt=media&token=f2bd9926-46a6-4b4f-873b-6bb6092079bc"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>MySQL</text>
                        </div> 
                        <div className="spanelement">
                        <img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/expologo.png?alt=media&token=1ee4537d-ecda-4cdf-a374-3ef4c81db3e9"} className="imageapple" alt="Home" height={30} width={30}  />
                       <text style={{color:'#c2c2c2', fontSize:'10px', fontFamily:'Verdana'}}>Expo</text>
                        </div>
                        </div>
                                                </div>

                                                <div style={{marginBottom:'100px'}}>

                                                </div>
</div>

);

export default About;