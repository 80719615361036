
import React, {Component, useEffect} from 'react';
import Toolbar from './components/Toolbar/Toolbar';
import './App.css';
/* import coding from './assets/coding1.jpg';
 */
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import About from './components/About/About';
import Work from './components/Work/Work';
import Education from './components/Education/Education';
import Certifications from './components/Certifications/Certifications';
import Projects from './components/Projects/Projects';
import Contacts from './components/Contacts/Contacts';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom'
import Gen from './components/GenProject/GenProject';


class Main extends Component {
  

  constructor(props) {
    super(props);
    this.state = {
      SideDrawerOpen: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const SideDrawerOpen = window.innerWidth > 768;
    this.setState({ SideDrawerOpen:false });
  }

  
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {SideDrawerOpen: !prevState.SideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({SideDrawerOpen : false})
   
  }
    
  render () {
    
   let backdrop
    if (this.state.SideDrawerOpen ) {
      backdrop=<Backdrop click={this.backdropClickHandler}/>;
    }
  return (
    
    

<div className='ciaociao'>



    <div className='main'>
    <Toolbar drawerClickHandler={this.drawerToggleClickHandler}/>
    <SideDrawer show={this.state.SideDrawerOpen}/>

    {backdrop}
    <main style={{padding:10}}>
      <div className='font-face-ModernSans'>Hello, I am</div>
      <div className='font-face-nwk' style={{ fontSize:'75px', fontFamily:'Newake'}}>Leonard Sutera</div>
      <div style={{ marginTop:'20px', fontSize:'50px', fontFamily:'Newake', color:'rgba(194,194,194,0.7)'}} className='inanimation1'>Software Engineer</div>
      <div style={{ marginTop:'20px', fontSize:'15px', fontFamily:'Verdana', color:'rgba(194,194,194,0.7)', paddingRight:'15%'}} className='inanimation2'>Building digital solutions for web and mobile</div>
      {/*Transforming ideas into stunning digital experiences. */ }
      </main>
      <div className='mb100px'>

</div>
      <div id='About' className='aboutAnimation'>
      <About/></div>
    <div id='Work'>
      <Work/>
</div>
    <div id='Education' className='educationAnimation'>
      <Education/>
</div>
      <div id='Certifications'>
      <Certifications />
      </div>

      <div id='Projects'>
      <Projects />
      </div>

      <div id='Contacts'>
      <Contacts />
      </div>



    <div style={{justifyContent:'center', color:'#c2c2c2', fontSize:'10px', display:'flex', marginTop:'100px', paddingBottom:'30px'}}>Copyright ©2023 by Leonard Sutera</div>
    </div>
  
    </div> 



  );
  }
}

export default Main;
