import React from "react";
import {BsFillBriefcaseFill} from 'react-icons/bs';

const Work = props => (
<div>
<div className="title">
<div className="couplework">
                        <span>
                        <BsFillBriefcaseFill 
                        style={{height:'22px', width:'22px', marginRight:'15px', color:'#c2c2c2'}}/>
                       <text style={{color:'#c2c2c2', fontSize:'22px'}}>Work</text>
                        </span>
                        </div>                   
</div>
<div className="cardwork">
{/* <div style={{backgroundColor:'#090961', borderRadius:'7px', padding:'2%'}}>
<text style={{color:'#c2c2c2',  fontFamily:'Verdana'}}>03/2023 - Today</text>
</div> */}
<div className="spanworkwork">
<img src={"https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/openreply.png?alt=media&token=931daf3b-ea5c-4996-aab0-36886607e8e4"} className="imagereply" alt="Home"  />

    <div style={{display:'flex', flexDirection:'column'}}>
    <text className="textdatework">03/2023 - Today</text> 
                       <text className="texttitlework">Front End Developer</text> 
                       <text className="textdescriptionwork">Writing features for web applications using Vue JS communicating with the back-end engineering team on daily basis</text> 
      </div>

                        </div>

</div>

<div style={{marginBottom:'100px'}}>

                                                </div>

</div>
);

export default Work;