const data = [
{
    text: 'Politecnico di Milano',
    date: '2022 - Today',
    type: "Master's degree",
    description:"ICT Engineering, Business and Innovation",
    category: {
        tag: 'medium',
        color: '#150b70',
    },
},

{
    text: 'Università degli Studi Mediterranea',
    date: '2019 - 2022',
    type: "Bachelor's degree",
    description:"Information Engineering",
    category: {
        tag: 'medium',
        color: '#150b70',
    },
},

{
    text: 'Liceo Scientifico Statale Leonardo da Vinci',
    date: '2014 - 2019',
    type: "High School Diploma",
    description:"Ordinary Course",
    category: {
        tag: 'medium',
        color: '#150b70',
    },
},

];

export default data; 