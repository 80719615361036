
import React from "react";
const CertItem = ({ data }) => (
    <div className="theCard">
            <div className="card-item-content">
                <img src={data.url}  style={{ width:'100%', opacity:'90%', borderRadius:'10px', height:'100%'}}alt="Home"  />
                <time style={{marginBottom:'-5px'}}>{data.time}</time>
                <p style={{color:'#c2c2c2', marginBottom:'-8px', fontFamily:'Newake', fontSize:'20px', padding:'5px'}}>{data.text}</p>
                <p style={{color:'#c2c2c2',  marginBottom:'-10px', fontSize:'13px', fontFamily:'Verdana', paddingInline:'5px', textAlign:'center'}}>{data.description}</p>
                <p style={{color:'#c2c2c2', fontSize:'15px', opacity:'70%'}}>{data.date}</p>
            </div>
            </div>

);
export default CertItem;

