import React, {Component, useEffect} from 'react';
import Toolbar from './components/Toolbar/Toolbar';
import './App.css';
/* import coding from './assets/coding1.jpg';
 */
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import About from './components/About/About';
import Work from './components/Work/Work';
import Education from './components/Education/Education';
import Certifications from './components/Certifications/Certifications';
import Projects from './components/Projects/Projects';
import Contacts from './components/Contacts/Contacts';
import Gen from './components/GenProject/GenProject';
import Main from './Main';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


class App extends Component {
  

  constructor(props) {
    super(props);
    this.state = {
      SideDrawerOpen: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const SideDrawerOpen = window.innerWidth > 768;
    this.setState({ SideDrawerOpen:false });
  }

  
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {SideDrawerOpen: !prevState.SideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({SideDrawerOpen : false})
   
  }
    
  render () {
    
   let backdrop
    if (this.state.SideDrawerOpen ) {
      backdrop=<Backdrop click={this.backdropClickHandler}/>;
    }
  return (
    
    

<Router>
      <div>
             

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/">
            <Main />
          </Route>
         
        </Switch>
      </div>
    </Router>



  );
  }
}

export default App;
