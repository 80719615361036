import React from "react";
import {FaUserAlt} from 'react-icons/fa';
import {BsFillBriefcaseFill} from 'react-icons/bs';
import {FaGraduationCap} from 'react-icons/fa';
import {TbCertificate} from 'react-icons/tb';
import {MdWeb} from 'react-icons/md';
import {AiFillInfoCircle} from 'react-icons/ai';

const scrollEducation = (event) => {
    event.preventDefault(); 
    const Education = document.getElementById('Education');
    if (Education) {
      Education.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
    } 
  };

  const scrollTop = (event) => {
    event.preventDefault(); 
    const Top = document.getElementById('Top');
    if (Top) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } 
  }

  const scrollAbout = (event) => {
    event.preventDefault(); 
    const About = document.getElementById('About');
    if (About) {
        About.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollWork = (event) => {
    event.preventDefault(); 
    const Work = document.getElementById('Work');
    if (Work) {
        Work.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollCertifications = (event) => {
    event.preventDefault(); 
    const Certifications = document.getElementById('Certifications');
    if (Certifications) {
        Certifications.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollProjects = (event) => {
    event.preventDefault(); 
    const Projects = document.getElementById('Projects');
    if (Projects) {
        Projects.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      } 
  }

  const scrollContacts = (event) => {
    event.preventDefault(); 
    const Contacts = document.getElementById('Contacts');
    if (Contacts) {
        Contacts.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
      });
      
      } 
  }

const SideDrawer = props => {
    let drawerClasses= 'side-drawer';
    if (props.show){
        drawerClasses = 'side-drawer open'
    }
    return(
    <nav className={drawerClasses}>
        <ul>
        
        <li>
                        <span>
                        <FaUserAlt style={{color:'#c2c2c2', height:'16px', width:'16px', marginRight:'10px'}}/>
                        <a href="#About" onClick={scrollAbout} style={{color:'#c2c2c2'}}>About</a>

                        </span>
                        </li>
                        <li>
                        <span>
                        <BsFillBriefcaseFill style={{color:'#c2c2c2', height:'16px', width:'16px', marginRight:'10px'}}/>
                        <a href="#Work" onClick={scrollWork} style={{color:'#c2c2c2'}}>Work</a>

                        </span>
                        </li>
                                           
                        <li>
                        <span>
                        <FaGraduationCap style={{color:'#c2c2c2', height:'18px', width:'18px', marginRight:'10px'}}/>
                        <a href="#Education" onClick={scrollEducation} style={{color:'#c2c2c2'}}>Education</a>

                        </span>
                        </li>
                        <li>
                        <span>
                        <TbCertificate style={{color:'#c2c2c2', height:'16px', width:'16px', marginRight:'10px'}}/>
                        <a href="#Certifications"onClick={scrollCertifications} style={{color:'#c2c2c2'}}>Certifications</a>

                        </span>
                        </li>
                        <li>
                        <span>
                        <MdWeb style={{color:'#c2c2c2', height:'16px', width:'16px', marginRight:'10px'}}/>
                        <a href="#Projects" onClick={scrollProjects} style={{color:'#c2c2c2'}}>Projects</a>

                        </span>
                        </li>
                        <li>
                        <span>
                        <AiFillInfoCircle style={{color:'#c2c2c2', height:'16px', width:'16px', marginRight:'10px'}}/>
                        <a href="#Contacts" onClick={scrollContacts} style={{color:'#c2c2c2'}}>Contacts</a>

                        </span>
                        </li>
                                </ul>
    </nav>
    );
};

export default SideDrawer;