/* import etsblured from '../../assets/etsblured.jpg';
import cyberchallreduced from '../../assets/cyberchallreduced.jpg' */

import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'





const data = [
{
    text: 'ETS Toeic',
    date: '08/2022',
    description:"B2 Certification",
    url: "https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/etsblured.jpg?alt=media&token=e6e556b2-1633-4056-b308-4929d0168bdb",
    category: {
        color: '#3478f6',
    },
},

{
    text: 'CyberChallengeIT',
    date: '05/2022',
    url: "https://firebasestorage.googleapis.com/v0/b/leonardsutera-fe047.appspot.com/o/cyberchallreduced.jpg?alt=media&token=2bd2265f-c0ff-4e3f-85f3-09bc893e6db2",
    description:"Certification of attendance",
    category: {
        color: '#3478f6',
    },
},



];




export default data; 