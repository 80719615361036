import React from "react";
import {TbCertificate} from 'react-icons/tb';
import Timeline from "./Timeline";






const Certifications = () => (
    <div>
    <div className="title">
<div className="couplework">
                        <span style={{marginBottom:'20px'}}>
                        <TbCertificate 
                        style={{height:'22px', width:'22px', marginRight:'15px', color:'#c2c2c2'}}/>
                       <text style={{color:'#c2c2c2', fontSize:'22px'}}>Certifications</text>
                        </span>
                        </div>                   
</div>
    <Timeline/>
    <div style={{marginBottom:'50px'}}/>
    </div>
);

export default Certifications;

